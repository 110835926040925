import React from "react"

import Img from "gatsby-image"
import "./index.css"
import "../style/font-style.css"
import Box from "@material-ui/core/Box"
import AOS from "aos"
import "aos/dist/aos.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import Head from "../components/head"
import Header from "../components/Header_normal"
import RecentNews from "../components/RecentNews"
import Profile from "../components/Profile"
import Media from "../components/Media"
import Content from "../components/content"
import Footer from "../components/footer"
import Pastevent from "../components/pastevent"
import { Hidden } from "@material-ui/core"

{
  /* <h1
  style={{


    
    background:
      "linear-gradient( to right,  rgba(69,179,224,1) 25%, rgba(0,51,153,1) 75% )",
    WebkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
  }}
>
  fasfasd
        </h1> */
}
export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    })
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Head />
      <Box
        // maxWidth="1300px"
        // width="95%"
        // m="auto"
        position="fixed"
        top="0"
        left="0"
        right="0"
      >
        <Header />
      </Box>
      <Box>
        <Media id="media" isTop={false} />
        <Footer />
      </Box>
    </>
  )
}
